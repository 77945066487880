<script>
import IconBase from "@/components/helpers/IconBase";
import IconSuccess from "@/components/icons/IconSuccess";

export default {
  components: { IconSuccess, IconBase },
};
</script>

<template>
  <div class="balance-refill-successful">
    <div class="balance-refill-successful__status">
      <div class="balance-refill-successful__icon">
        <icon-base
          icon-name="icon-success"
          icon-fill="#3E8D60"
          height="40"
          width="40"
          view-box-width="40"
          view-box-height="40"
        >
          <icon-success />
        </icon-base>
      </div>
      <div class="balance-refill-successful__info">
        <h4>Оплата прошла успешно</h4>
        <p>Баланс пополнен</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-refill-successful {
  padding: $space-xxl;

  &__status {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 12px;
  }

  &__info {
    h4 {
      @include text-1;
      color: $dark-primary;
      margin-bottom: $space-xs;
    }

    p {
      @include body-1;
      color: $dark-sixth;
    }
  }

  @media (max-width: 576px) {
    padding: $space-xxl $space-l;
  }
}
</style>
